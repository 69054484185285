// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paslaugos-js": () => import("./../../../src/pages/paslaugos.js" /* webpackChunkName: "component---src-pages-paslaugos-js" */),
  "component---src-pages-produktai-js": () => import("./../../../src/pages/produktai.js" /* webpackChunkName: "component---src-pages-produktai-js" */),
  "component---src-templates-apie-js": () => import("./../../../src/templates/apie.js" /* webpackChunkName: "component---src-templates-apie-js" */),
  "component---src-templates-basic-js": () => import("./../../../src/templates/basic.js" /* webpackChunkName: "component---src-templates-basic-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */)
}

